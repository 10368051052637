import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "links"
    }}>{`Links`}</h1>
    <p>{`Here you will find relative links which we use alot`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Tool`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`URL`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Corporate site`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Hybrit`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://hybrit.org"
            }}>{`https://hybrit.org`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Version control`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Github`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://github.com/hybrit-dev"
            }}>{`https://github.com/hybrit-dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Backlog management`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Jira`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://hybrit.atlassian.net/jira"
            }}>{`https://hybrit.atlassian.net/jira`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Password manager`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Lastpass`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://lastpass.com/"
            }}>{`https://lastpass.com/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cloud`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Google Cloud`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://console.cloud.google.com/home"
            }}>{`https://console.cloud.google.com/home`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cloud`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Azure`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://portal.azure.com"
            }}>{`https://portal.azure.com`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CI/CD`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Azure DevOps`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://dev.azure.com/hybritdev"
            }}>{`https://dev.azure.com/hybritdev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Artifacts`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Nexus`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://repository.dev.hybrit.io"
            }}>{`https://repository.dev.hybrit.io`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DNS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Amazon`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "https://console.aws.amazon.com/console/home"
            }}>{`https://console.aws.amazon.com/console/home`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      